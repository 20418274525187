import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import password from '@/router/password'
import users from '@/router/users'
import types from '@/router/types'
import operations from '@/router/operations'
import machines from '@/router/machines'
import orders from '@/router/orders'
import clients from '@/router/clients'
import appmodule from '@/router/appmodule'
import profiles from '@/router/profiles'
import menus from '@/router/menus'
import workshift from '@/router/workshift'
import calendarexception from '@/router/calendarexception'
import unity from '@/router/unity'
import reports from '@/router/reports'
import productgroup from '@/router/productgroup'
import productfamily from '@/router/productfamily'
import product from '@/router/product'
import appointments from '@/router/appointments'
import dynamicField from '@/router/dynamicfield'
import clientorder from '@/router/clientorder'
import schedule from '@/router/schedule'
import workstationclassification from '@/router/workstationclassification'
// :routes-imports://
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/machine-appointments',
    name: 'machine.appointments',
    component: () => import('@/views/MachineAppointments.vue'),
    meta: {
      pageTitle: 'Área de Trabalho',
      breadcrumb: [
        {
          text: 'Área de Trabalho',
          active: true,
        },
      ],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...routes,
    ...password,
    ...users,
    ...types,
    ...operations,
    ...machines,
    ...orders,
    ...clients,
    ...appmodule,
    ...profiles,
    ...menus,
    ...workshift,
    ...calendarexception,
    ...unity,
    ...reports,
    ...productgroup,
    ...productfamily,
    ...product,
    ...appointments,
    ...dynamicField,
    ...clientorder,
    ...schedule,
    ...workstationclassification,
    // :routes-unpack://
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'page-error-404' || to.name === 'login' || to.name === 'perdi-minha-senha'
    || to.name === 'recuperar-senha') {
    return next()
  }
  if (to.name) {
    if (!isUserLoggedIn() && to.name !== 'login') {
      router.push('login')
      return false
    }
  }
  next()
  return true
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
