<template>
  <div class="h-100 module-menu">
    <b-link to="/home">
      <span class="brand-logo">

        <b-img
          width="36"
          height="42"
          :src="logoImage"
          alt="Apontamento Digital"
        />
      </span>

    </b-link>
    <b-button
      v-b-tooltip.hover.right
      title="Início"
      variant="primary"
      class="mt-1 btn-icon rounded-circle"
      @click="() => $router.push({ 'name': 'home' })"
    >
      <feather-icon icon="HomeIcon" />
    </b-button>
    <b-button
      v-for="module in modules"
      :key="module.uuid"
      v-b-tooltip.hover.right
      :title="module.name"
      variant="primary"
      class="mt-1 btn-icon rounded-circle"
      @click="access(module)"
    >
      <feather-icon :icon="module.icon" />
    </b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { BLink, VBTooltip } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import { getCurrentModule, setCurrentModule } from '@/auth/utils'

export default {
  name: 'ModuleMenu',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BLink,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    const { appLogoImage } = $themeConfig.app
    return {
      currentUser: null,
      modules: [],
      logoImage: appLogoImage,
    }
  },
  watch: {
    async userData(value) {
      if (value.uuid) {
        await this.getModules()
      }
    },
  },
  async created() {
    this.currentUser = await this.me()
    if (this.currentUser && this.currentUser.uuid) {
      await this.getModules()
    }
  },
  methods: {
    ...mapActions('auth', ['me', 'getMenusFromModule']),
    async getModules() {
      this.modules = await this.$http.get('auth/me/modules')

      const currentModule = getCurrentModule()
      if (!currentModule) {
        await this.access(this.modules[0])
      }
    },
    async access(item) {
      if (!item.uuid) {
        return
      }
      setCurrentModule(item)
      await this.getMenusFromModule(item.uuid)
    },
  },
}
</script>

<style scoped>
.module-menu {
  padding-top: 30px;
  background-color: #45BB6D;
  color: #FFF;
  text-align: center;
  width: 50px;
  position: fixed;
}
</style>
